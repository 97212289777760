import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Page } from 'features/app/types';
import AppPadding from 'sci-ui-components/AppPadding/AppPadding';
import useAuth from 'features/auth/useAuth';
import ModalDialog from 'sci-ui-components/ModalDialog/ModalDialog';
import FieldLabelBox from 'sci-ui-components/forms/FieldLabelBox/FieldLabelBox';
import TextField from 'sci-ui-components/forms/TextField/TextField';
import FormFieldsRow from 'sci-ui-components/forms/FormFieldsRow/FormFieldsRow';
import { showError } from 'services/toaster';

const formId = 'login';
const usernameFieldId = `${formId}-name`;
const passwordFieldId = `${formId}-password`;

type FormValues = {
  username: string;
  password: string;
};
const validationSchema: Yup.SchemaOf<FormValues> = Yup.object().shape({
  username: Yup.string().min(1).required('Required'),
  password: Yup.string().min(1).required('Required'),
});

const Login: Page = () => {
  const { loginWithBasicAuth } = useAuth();

  const handleSubmit = async (values: FormValues, { setSubmitting, resetForm }: FormikHelpers<FormValues>) => {
    try {
      await loginWithBasicAuth({
        username: values.username,
        password: values.password,
        redirectUrl: '/health',
      });
    } catch (err) {
      showError({
        message: 'Failed to login',
      });
    }
    setSubmitting(false);
    resetForm();
  };

  return (
    <AppPadding>
      <Formik
        initialValues={{
          username: '',
          password: '',
        }}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ values, handleChange, errors, isSubmitting }) => (
          <ModalDialog
            open
            title="Sign In"
            okText="Sign In"
            closable={false}
            okButtonProps={{
              htmlType: 'submit',
              form: formId,
              disabled: isSubmitting,
            }}
          >
            <Form id={formId}>
              <FormFieldsRow>
                <FieldLabelBox label="Username" fieldId={usernameFieldId} error={errors.username}>
                  <TextField
                    id={usernameFieldId}
                    name="username"
                    value={values.username}
                    onChange={(value, name) => handleChange({ target: { name, value } })}
                  />
                </FieldLabelBox>
              </FormFieldsRow>
              <FormFieldsRow>
                <FieldLabelBox label="Password" fieldId={passwordFieldId} error={errors.password}>
                  <TextField
                    id={passwordFieldId}
                    name="password"
                    type="password"
                    value={values.password}
                    onChange={(value, name) => handleChange({ target: { name, value } })}
                    autoComplete="current-password"
                  />
                </FieldLabelBox>
              </FormFieldsRow>
            </Form>
          </ModalDialog>
        )}
      </Formik>
    </AppPadding>
  );
};

Login.shell = 'minimal';

export default Login;
