import { forwardRef, ReactNode, Ref } from 'react';
import clsx from 'clsx';
import { AnimatePresence, motion, MotionProps } from 'framer-motion';

import classes from './FieldLabelBox.module.scss';

const extraMotionProps: MotionProps = {
  animate: { height: 'auto' },
  initial: { height: 0 },
  exit: { height: 0 },
};

export interface FieldLabelBoxProps {
  className?: string;
  contentClassName?: string;
  labelClassName?: string;
  label?: ReactNode;
  labelPosition?: 'top' | 'left';
  children: ReactNode;
  adornmentRight?: ReactNode | ReactNode[];
  fieldId?: string;
  error?: string;
  required?: boolean;
  optional?: boolean;
  disabled?: boolean;
  extraInfo?: string;
  dense?: boolean;
  variant?: 'default' | 'neater';
  description?: ReactNode;
}

export default forwardRef(function FieldLabelBox(
  {
    className,
    contentClassName,
    labelClassName,
    label,
    labelPosition,
    children,
    adornmentRight,
    fieldId,
    error,
    required,
    optional,
    disabled,
    extraInfo,
    dense = false,
    variant = 'default',
    description,
  }: FieldLabelBoxProps,
  ref: Ref<HTMLDivElement>
) {
  return (
    <section
      ref={ref}
      className={clsx(
        classes.root,
        {
          [classes.disabled]: disabled,
          [classes.dense]: dense,
          [classes.labelLeftRoot]: labelPosition === 'left',
          [classes.neater]: variant === 'neater',
        },
        className
      )}
    >
      {!!label && (
        <div className={clsx(classes.labelRow, labelPosition === 'left' && classes.labelLeftRow)}>
          <label htmlFor={fieldId} className={clsx(classes.label, labelClassName)}>
            {label}
            {!!required && <span className={classes.hint}> (Required)</span>}
            {!!optional && <span className={classes.hint}> (Optional)</span>}
          </label>
          {!!adornmentRight && <div className="ml-auto">{adornmentRight}</div>}
        </div>
      )}
      {!!description && <div className="text-sm text-gray-500 mb-2">{description}</div>}
      <div className={clsx(classes.content, contentClassName)}>{children}</div>
      <AnimatePresence>
        {!!error && (
          <motion.p key="error" className={classes.error} {...extraMotionProps}>
            {error}
          </motion.p>
        )}
        {!!extraInfo && (
          <motion.p key="extra" className={classes.extra} {...extraMotionProps}>
            {extraInfo}
          </motion.p>
        )}
      </AnimatePresence>
    </section>
  );
});
