import clsx from 'clsx';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import IconButton, { IconButtonProps } from '../../buttons/IconButton/IconButton';

import classes from './ClearIconButton.module.scss';

export type ClearIconButtonProps = Omit<IconButtonProps, 'faIcon' | 'htmlType' | 'title' | 'size' | 'color'> & {
  visible: boolean;
};

export default function ClearIconButton({ className, visible, ...other }: ClearIconButtonProps) {
  return (
    <IconButton
      {...other}
      htmlType="button"
      faIcon={faXmark}
      className={clsx(
        classes.clearButton,
        {
          [classes.hideClear]: !visible,
        },
        className
      )}
      tabIndex={visible ? undefined : -1}
      title="clear"
      size="small"
      color="grey"
    />
  );
}
